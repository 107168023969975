import { graphql, Link } from "gatsby"
import HtmlToReact, { Parser as HtmlToReactParser } from "html-to-react"
import React from "react"
import Layout from "../components/layout"

const isValidNode = function () {
  return true
}

const EventTemplate = ({ data: { strapiEvent: event } }) => {
  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React)
  const preProcessingInstructions = [
    {
      // Custom <h1> processing
      shouldPreprocessNode: function (node) {
        return node.name === "img"
      },
      preprocessNode: function (node, children) {
        return (node.attribs.src = `https://strapi-webnyay-backend-prod-test.webnyay.in${node.attribs.src}`)
      },
    },
    {
      // Custom <h1> processing
      shouldPreprocessNode: function (node) {
        return (
          node.parent && node.parent.name && node.parent.name === "blockquote"
        )
      },
      preprocessNode: function (node, children) {
        return (node.children[0].data = `"${node.children[0].data}"`)
      },
    },
    {
      // Custom <h1> processing
      shouldPreprocessNode: function (node) {
        return node.name === "table"
      },
      preprocessNode: function (node, children) {
        return (node.attribs = { id: "table" })
      },
    },
  ]
  const processingInstructions = [
    {
      // Anything else
      shouldProcessNode: function (node) {
        return true
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ]
  const htmlToReactParser = new HtmlToReactParser()

  return (
    <Layout
      navClass={true}
      title={event.Title}
      ogImage={event.image.publicURL}
      ogDescription={event.Content.substr(0, 300)}
    >
      <div className="article">
        <div className="article__body">
          <div className="article__title-container">
            <p className="article__date">{event.date}</p>
            <div className="article__bar"></div>
            <h1 className="article__title">{event.Title}</h1>
            <div className="u-margin-top-medium"></div>
            <Link to="/events">
              <button className="icon-button">
                <i className="material-icons">arrow_back_ios</i>
              </button>
            </Link>
          </div>
          <div className="article__content">
            <img src={event.image.publicURL} alt="" />
            <div className="article__content-body">
              {htmlToReactParser.parseWithInstructions(
                event.Content,
                isValidNode,
                processingInstructions,
                preProcessingInstructions
              )}
            </div>
          </div>
        </div>
        <div className="u-padding-top-xl"></div>
      </div>
    </Layout>
  )
}

export default EventTemplate

export const query = graphql`
  query EventTemplate($id: Int!) {
    strapiEvent(strapiId: { eq: $id }) {
      image {
        publicURL
      }
      date(formatString: "dddd MMMM Do", locale: "America/Curacao")
      Title
      Content
      strapiId
    }
  }
`
